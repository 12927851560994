@charset "UTF-8";

.modally-init {
    display: none;
}

.modally-wrap {
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 1000;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 0;
    display: none;
    box-sizing: border-box;
    transition: opacity 500ms ease-in-out;
}

.modally-wrap .modally-underlay {
    position: fixed;
    background: rgba(23, 23, 23, 0.25);
    cursor: pointer;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.modally {
    margin: 0px auto;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    margin-top: 20px;
    margin-bottom: 20px;
    width: calc(100% - 40px);
    height: auto;
    background: white;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    padding-top: 48px;
}

.modally .modally-close-button {
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    cursor: pointer;

    background: transparent;

	position: absolute;
    top: 0px;
    right: 0px;
    padding: 12px 20px;
    font-size: 32px;
    line-height: 23px;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
    font-weight: 300;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}

/* Remove excess padding and border in Firefox 4+ */
.modally .modally-close-button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.modally-table {
    display: table;
    width: 100%;
    min-height: 100%;
}

.modally-cell {
    display: table-cell;
    vertical-align: middle;
    position: relative;
}

.modally .iframe-landing {
    position: relative
}

.modally .iframe-landing iframe,
.modally .iframe-landing video {
    display: block;
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0px;
    left: 0px;
	border: none;
}

@media screen and (min-width: 960px) {
    .modally .modally-close:hover {
        opacity: 0.5;
    }
}

@media screen and (max-width: 959px) {
    .modally {
        height: auto;
        max-width: none;
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: calc(100% - 40px);
    }
}
